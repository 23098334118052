import {
  Button,
  Card,
  CardMedia,
  Divider,
  Grid,
  Stack,
  useTheme,
} from "@mui/material";
import Paper from "common/components/Paper";
import Typography from "common/components/Typography";
import CharacterCard from "pages/Menu/BoxManager/CharacterCard";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PicksList from "./PicksList";
import { useSimpleReducer } from "common/hooks/useSimpleReducer";
import { DraftContext, DraftProvider } from "./context";
import { RoomContext } from "App/context";
import DraftManager from "./DraftManager";
import ActionButton from "./ActionButton";
import BoxList from "./BoxList";
import { useSocket } from "common/hooks/useSocket";
import { CharacterBox } from "types/Player/type";
import { DraftCharacter, DraftStep, TimerState } from "types/Draft/type";
import { whoAMI } from "common/utils";
import BansList from "./BansList";
import { useNavigate, useBeforeUnload } from "react-router-dom";
import EndDraft from "./EndDraft";
import { useTranslation } from "react-i18next";
import { Role } from "types/Room/type";
import LeaveDraft from "./ConfirmLeaveDraft";
import { useInputKeyEvent } from "common/hooks/useKonamiCode";

type DraftType = {};

const Draft = (props: DraftType) => {
  const { t } = useTranslation();
  const socket = useSocket();
  const navigate = useNavigate();
  const key = useInputKeyEvent();
  const {
    dispatch,
    currentStep,
    timerPlayer1,
    timerStatePlayer1,
    timerPlayer2,
    timerStatePlayer2,
    bansPlayer1,
    bansPlayer2,
    picksPlayer1,
    picksPlayer2,
    selectedBansPlayer1,
    selectedBansPlayer2,
    selectedPicksPlayer1,
    selectedPicksPlayer2,
    toBanPlayer1,
    toBanPlayer2,
  } = useContext(DraftContext);
  const {
    admin,
    player1,
    player2,
    draft,
    balancingBansPlayer1,
    jokerBansPlayer1,
    balancingBansPlayer2,
    jokerBansPlayer2,
  } = useContext(RoomContext);

  const [endDraft, setEndDraft] = useState<boolean>(false);
  const [openLeave, setOpenLeave] = useState<boolean>(false);
  const theme = useTheme();
  const role = whoAMI(socket, player1, player2);

  const subscribeEvents = () => {
    socket.on("new-step", (step: DraftStep) => {
      if (step.action === "Ban") {
        if (step.player === "Player 1") {
          dispatch({ currentStep: step, selectedBansPlayer1: [] });
        } else if (step.player === "Player 2") {
          dispatch({ currentStep: step, selectedBansPlayer2: [] });
        } else {
          dispatch({
            currentStep: step,
            selectedBansPlayer1: [],
            selectedBansPlayer2: [],
          });
        }
      } else {
        if (step.player === "Player 1") {
          dispatch({ currentStep: step, selectedPicksPlayer1: [] });
        } else if (step.player === "Player 2") {
          dispatch({ currentStep: step, selectedPicksPlayer2: [] });
        } else {
          dispatch({
            currentStep: step,
            selectedPicksPlayer1: [],
            selectedPicksPlayer2: [],
          });
        }
      }
    });
    socket.on("timer-player1", (timerPlayer1: number) =>
      dispatch({ timerPlayer1 })
    );
    socket.on("timer-state-player1", (timerStatePlayer1: TimerState) =>
      dispatch({ timerStatePlayer1 })
    );
    socket.on("timer-player2", (timerPlayer2: number) =>
      dispatch({ timerPlayer2 })
    );
    socket.on("timer-state-player2", (timerStatePlayer2: TimerState) =>
      dispatch({ timerStatePlayer2 })
    );
    socket.on("selected-chars-pick", (chars: DraftCharacter[], role: Role) => {
      if (role === "Player 1") dispatch({ selectedPicksPlayer1: chars });
      else dispatch({ selectedPicksPlayer2: chars });
    });
    socket.on("chars-pick", (chars: DraftCharacter[], role: Role) => {
      if (role === "Player 1") {
        dispatch({
          picksPlayer1: chars,
          selectedPicksPlayer1: undefined,
        });
      } else {
        dispatch({
          picksPlayer2: chars,
          selectedPicksPlayer2: undefined,
        });
      }
    });
    socket.on("selected-chars-ban", (chars: DraftCharacter[], role: Role) => {
      if (role === "Player 1") dispatch({ selectedBansPlayer1: chars });
      else dispatch({ selectedBansPlayer2: chars });
    });
    socket.on("hide-ban", (chars: DraftCharacter[], role: Role) => {
      if (role === "Player 1") {
        dispatch({
          toBanPlayer1: chars,
          selectedBansPlayer1: undefined,
        });
      } else {
        dispatch({
          toBanPlayer2: chars,
          selectedBansPlayer2: undefined,
        });
      }
    });
    socket.on("chars-ban", (chars: DraftCharacter[], role: Role) => {
      if (role === "Player 1") {
        dispatch({
          bansPlayer1: chars,
          selectedBansPlayer1: undefined,
        });
      } else {
        dispatch({
          bansPlayer2: chars,
          selectedBansPlayer2: undefined,
        });
      }
    });
    socket.on(
      "reveal-ban",
      (banCharsP1: DraftCharacter[], banCharsP2: DraftCharacter[]) => {
        dispatch({
          bansPlayer1: banCharsP1,
          bansPlayer2: banCharsP2,
          toBanPlayer1: undefined,
          toBanPlayer2: undefined,
        });
      }
    );

    socket.on("end-draft", () => {
      setEndDraft(true);
    });

    socket.on("admin-left", () => {
      navigate("/");
    });
  };

  const formatTimer = (time: number) => {
    const timeLeft = draft.timer - time;
    let res = "";
    const minutes = Math.floor(timeLeft / 60);
    if (minutes > 0) res += minutes + ":";
    const seconds = timeLeft % 60;
    if (seconds < 10) res += "0" + seconds;
    else res += seconds;
    return res;
  };

  const unselectCharacter = (
    character: DraftCharacter,
    list?: DraftCharacter[]
  ) => {
    const index = list?.findIndex((c) => c.name === character.name);
    if (index !== undefined && index !== -1) {
      list?.splice(index, 1);
    }
    return list;
  };

  useEffect(() => {
    if (key === "Escape") setOpenLeave(!openLeave);
  }, [key]);

  useEffect(() => {
    subscribeEvents();

    return () => {
      socket.removeListener("new-step");
      socket.removeListener("timer-player1");
      socket.removeListener("timer-state-player1");
      socket.removeListener("timer-player2");
      socket.removeListener("timer-state-player2");
      socket.removeListener("selected-chars-pick");
      socket.removeListener("chars-pick");
      socket.removeListener("selected-chars-ban");
      socket.removeListener("hide-ban");
      socket.removeListener("chars-ban");
      socket.removeListener("reveal-ban");
      socket.removeListener("end-draft");
      socket.removeListener("admin-left");
    };
  }, []);

  return endDraft ? (
    <EndDraft />
  ) : (
    <Stack
      direction="row"
      justifyContent="space-between"
      gap={3}
      height="100%"
      sx={{
        background: ({ palette }) =>
          `radial-gradient(${
            currentStep?.action === "Ban"
              ? palette.background.ban
              : palette.common.player1.bgConstel
          }99, ${palette.background.default})`,
      }}
    >
      {admin ? <DraftManager /> : ""}
      <LeaveDraft open={openLeave} setOpen={setOpenLeave} />
      <PicksList
        player={"player1"}
        playerName={player1?.pseudo ?? ""}
        picks={picksPlayer1}
        selected={selectedPicksPlayer1}
        balancingBans={balancingBansPlayer1}
        jokerBans={jokerBansPlayer1}
        onClick={
          currentStep?.action === "Pick" &&
          role === "Player 1" &&
          (currentStep?.player === "Player 1" || currentStep?.player === "Both")
            ? (character: DraftCharacter) =>
                socket.emit(
                  "send-selected-chars-pick",
                  unselectCharacter(character, selectedPicksPlayer1)
                )
            : undefined
        }
      />
      <Stack direction="column" height="100%" gap={2} flexGrow="1" py="1rem">
        <Stack direction="row" justifyContent="space-between">
          <BansList
            color={theme.palette.common.player1.banCard}
            banSteps={draft.steps.filter(
              (step) =>
                step.action === "Ban" &&
                (step.player === "Player 1" || step.player === "Both")
            )}
            bans={bansPlayer1}
            selected={selectedBansPlayer1}
            hiddenBans={toBanPlayer1}
            hideBans={
              currentStep?.action === "Ban" &&
              role === "Player 2" &&
              currentStep?.player === "Both"
            }
            onClick={
              currentStep?.action === "Ban" &&
              role === "Player 1" &&
              (currentStep?.player === "Player 1" ||
                currentStep?.player === "Both")
                ? (character: DraftCharacter) =>
                    socket.emit(
                      "send-selected-chars-ban",
                      unselectCharacter(character, selectedBansPlayer1)
                    )
                : undefined
            }
          />
          <Stack alignItems="center" width="100%">
            <Typography size="m">
              {currentStep?.action === "Pick"
                ? t("Phase de pick")
                : currentStep?.action === "Ban"
                ? t("Phase de ban")
                : ""}
            </Typography>
            <Stack
              width="100%"
              direction="row"
              justifyContent="space-evenly"
              gap={2}
            >
              <Typography
                size="m"
                color={timerStatePlayer1 === "Play" ? "white" : "gray"}
                textAlign="end"
                width="100%"
              >
                {formatTimer(timerPlayer1)}
              </Typography>
              <Divider orientation="vertical" />
              <Typography
                size="m"
                color={timerStatePlayer2 === "Play" ? "white" : "gray"}
                textAlign="start"
                width="100%"
              >
                {formatTimer(timerPlayer2)}
              </Typography>
            </Stack>
          </Stack>
          <BansList
            color={theme.palette.common.player2.banCard}
            banSteps={draft.steps.filter(
              (step) =>
                step.action === "Ban" &&
                (step.player === "Player 2" || step.player === "Both")
            )}
            bans={bansPlayer2}
            selected={selectedBansPlayer2}
            hiddenBans={toBanPlayer2}
            hideBans={
              currentStep?.action === "Ban" &&
              role === "Player 1" &&
              currentStep?.player === "Both"
            }
            onClick={
              currentStep?.action === "Ban" &&
              role === "Player 2" &&
              (currentStep?.player === "Player 2" ||
                currentStep?.player === "Both")
                ? (character: DraftCharacter) =>
                    socket.emit(
                      "send-selected-chars-ban",
                      unselectCharacter(character, selectedBansPlayer2)
                    )
                : undefined
            }
          />
        </Stack>
        <BoxList />
        {whoAMI(socket, player1, player2) !== "Spectator" && <ActionButton />}
      </Stack>
      <PicksList
        player={"player2"}
        playerName={player2?.pseudo ?? ""}
        picks={picksPlayer2}
        selected={selectedPicksPlayer2}
        balancingBans={balancingBansPlayer2}
        jokerBans={jokerBansPlayer2}
        onClick={
          currentStep?.action === "Pick" &&
          role === "Player 2" &&
          (currentStep?.player === "Player 2" || currentStep?.player === "Both")
            ? (character: DraftCharacter) =>
                socket.emit(
                  "send-selected-chars-pick",
                  unselectCharacter(character, selectedPicksPlayer2)
                )
            : undefined
        }
      />
    </Stack>
  );
};

const DraftWithContext = () => {
  return (
    <DraftProvider>
      <Draft />
    </DraftProvider>
  );
};

export default DraftWithContext;
