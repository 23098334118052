import { useSimpleReducer } from "common/hooks/useSimpleReducer";
import { Room } from "types/Room/type";
import React, { PropsWithChildren, createContext, useState } from "react";
import { DraftCharacter, DraftStep } from "types/Draft/type";
import { CharacterBox } from "types/Player/type";
import { ElementType } from "types/Character/type";

type DraftType = {
  element: ElementType | null;
  rarity: number | null;
  player: ("Player 1" | "Player 2") | null;
  search: string;
  sortConstelDesc: boolean;
};

type FilterContextType = DraftType & {
  dispatch: (action: Partial<DraftType>) => void;
};

export const defaultValues: FilterContextType = {
  element: null,
  rarity: null,
  player: null,
  sortConstelDesc: false,
  search: "",
  dispatch: () => {},
};
export const FilterContext = createContext<FilterContextType>(defaultValues);

export const FilterProvider = (
  props: PropsWithChildren<Partial<FilterContextType>>
) => {
  const [state, dispatch] = useSimpleReducer<FilterContextType>({
    ...defaultValues,
    ...props,
  });

  return (
    <FilterContext.Provider value={{ ...state, dispatch }}>
      {props.children}
    </FilterContext.Provider>
  );
};
