import { Badge, Stack } from "@mui/material";
import { RoomContext } from "App/context";
import GenshinButton from "common/components/GenshinButton";
import SkewButton from "common/components/SkewButton";
import BoxListWithContext from "pages/Draft/BoxList";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DraftCharacter } from "types/Draft/type";
import { Role, Room } from "types/Room/type";
import { BalancingBansContext } from "./context";
import { whoAMI } from "common/utils";
import { useSocket } from "common/hooks/useSocket";

type BalancingBansType = {
  badgeQuantity?: number;
  whoBan?: "player1" | "player2";
};

const BalancingBans = (props: BalancingBansType) => {
  const { badgeQuantity = 0, whoBan = "player1" } = props;
  const { t } = useTranslation();
  const { dispatch: dispatchRoom, player1, player2 } = useContext(RoomContext);
  const { dispatch, selectingBans, selectedBansPlayer1, selectedBansPlayer2 } =
    useContext(BalancingBansContext);
  const socket = useSocket();

  const role = whoAMI(socket, player1, player2);

  const subscribeEvents = () => {
    socket.on("show-balancing-bans", (room: Room, role?: Role) => {
      dispatchRoom({
        balancingBansPlayer1: room.balancingBansPlayer1 ?? undefined,
        balancingBansPlayer2: room.balancingBansPlayer2 ?? undefined,
      });
      dispatch({
        selectingBans: role ?? undefined,
        selectedBansPlayer1: role === "Player 1" ? [] : undefined,
        selectedBansPlayer2: role === "Player 2" ? [] : undefined,
      });
    });
    socket.on(
      "selected-balanced-ban",
      (chars: DraftCharacter[], role: Role) => {
        if (role === "Player 1") {
          dispatch({
            selectedBansPlayer1: chars,
          });
        } else {
          dispatch({
            selectedBansPlayer2: chars,
          });
        }
      }
    );
  };

  useEffect(() => {
    subscribeEvents();

    return () => {
      socket.removeListener("show-balancing-bans");
      socket.removeListener("selected-balanced-ban");
    };
  }, []);

  return player1 !== undefined && player2 !== undefined ? (
    <Stack
      alignItems="center"
      direction="column"
      height="100%"
      gap={2}
      width={selectingBans !== undefined ? "100%" : undefined}
    >
      <Badge
        color={whoBan === "player1" ? "info" : "error"}
        badgeContent={Math.abs(badgeQuantity)}
        anchorOrigin={{
          vertical: "top",
          horizontal: whoBan === "player1" ? "left" : "right",
        }}
      >
        <GenshinButton
          disabled={
            role === "Spectator" ||
            player1 === undefined ||
            player2 === undefined ||
            (selectingBans !== undefined && selectingBans !== role)
          }
          variant="contained"
          onClick={() => {
            if (role !== "Spectator") {
              if (selectingBans !== undefined) {
                socket.emit("on-click-show-balancing-bans");
              } else {
                socket.emit("on-click-show-balancing-bans", role);
              }
            }
          }}
        >
          {t("Bans d'équilibrage")}
        </GenshinButton>
      </Badge>
      {selectingBans !== undefined && (
        <Stack
          direction="column"
          alignItems="center"
          height="0px"
          flexGrow={1}
          width="100%"
          gap={1}
        >
          <BoxListWithContext />
          {selectingBans === role && (
            <SkewButton
              onClick={() =>
                socket.emit(
                  "send-balanced-ban",
                  selectedBansPlayer1 ?? selectedBansPlayer2
                )
              }
            >
              {t("Bannir")}
            </SkewButton>
          )}
        </Stack>
      )}
    </Stack>
  ) : (
    <></>
  );
};

export default BalancingBans;
