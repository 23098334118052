import API from "service/root/rootApi";
import { DraftParams } from "./type";
import { CharacterBox } from "service/player/type";
import { Character } from "types/Character/type";

export const getDraftParams = async (
  scorePlayer1: number,
  scorePlayer2: number,
  boxPlayer1: CharacterBox[],
  boxPlayer2: CharacterBox[]
) => {
  const response = await API.post<DraftParams>("/draftParams", {
    scorePlayer1,
    scorePlayer2,
    boxPlayer1,
    boxPlayer2,
  });
  return response.data;
};

export const getCharacters = async () => {
  const response = await API.get<Character[]>("/characters");
  //Hardcode les elements du traveler en attendant le BE
  const index = response.data.findIndex((c) => c.name === "Voyageur");
  response.data[index].element = ["Anemo", "Geo", "Electro", "Dendro", "Hydro"];
  return response.data;
};
